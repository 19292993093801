.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-input-section {
  padding: 10px;
  text-align: center;
  margin-left: 25%;
}

.form-input-container {
  justify-content: center;
  direction: "column";
  text-align: center;
  /* border: solid black 1px; */
}

.imgdisplay {
  width: 100%;
  height: 100%;
  /* padding: 1em; */
}

.imgitem {
  width: inherit;
  height: auto;
  padding: 4px;
  margin: 0px;
}
.menulogo {
  height: 32px;
  width: 32px;
  padding: 4px;
}
.ccbanner {
  margin-top: 50px;
  width: inherit;
  height: auto;
  padding: 0px;
  margin: 0px;
}
.hmpsection {
  padding-top:4%;
  font-size: xx-large;
  float: left;
}
.hmpsectionr {
  padding-top:4%;
  font-size: xx-large;
  float: right;
}
.hmrightfloater {
  float: right;
  /* height: 200px; */
  width: 60%;
  margin-right: 3%;
  margin-bottom: 3%;
  padding-top:3%;
  padding-left: 1%;
  padding-right: 3%;
}
.hmleftfloater {
  float: left;
  /* height: 200px; */
  width: 55%;
  margin-left: 5%;
  margin-bottom: 3%;
  padding-top:1%;
  padding-left: 7%;
  padding-right: 1%;
}
.pagecontainer {
  margin-top: 30px;
  padding-top: 20px;
}
.packagepricing {
  text-align: center;
}
.wwmrightfloater {
  float: right;
  /* height: 200px; */
  width: 40%;
  margin-right: 3%;
  margin-bottom: 3%;
  padding-top:3%;
  padding-left: 1%;
  padding-right: 3%;
}
.wwmleftfloater {
  float: left;
  /* height: 200px; */
  width: 35%;
  margin-left: 3%;
  margin-bottom: 3%;
  padding-top:3%;
  padding-left: 3%;
  padding-right: 1%;
}
.wwmsectionheader {
  text-align: center;
  margin-top: 7%;
}
.wwmsection {
  padding-left:2%;
  padding-top:2%;
  padding-right:2%;
  padding-bottom: 5%
}
.errorblock {
  background-color: #01DCE6;
  color: red;
  margin-bottom: 12%;
}
.successfulSubmit {
  padding-left: 5%;
  padding-right: 5%;
}
.shtop {
  font-size: x-large;
}
.shps {
  background-color: #01DCE6;
  color: #018F96;
  border-bottom: solid 1px #2A0E4D;
  border-top: solid 1px #2A0E4D;
  font-size: x-large;
}
.shp {
  background-color: #948C9E;
  color: #431F70;
  border-bottom: solid 1px #2A0E4D;
  border-top: solid 1px #2A0E4D;
}
.sshp {
  color: #927520;
  background-color: #FFD65C;
  border-bottom: solid 1px #92908C;
  border-top: solid 1px #92908C;
}
.menuitem {
  text-decoration: none;
  background-color: #FFD65C !important;
  color: #927520 !important;
}
.menuitem:hover {
  text-decoration: none;
  color: #FFD65C !important;
  background-color: #927520 !important;
}
.pagebuttonitem {
  text-decoration: none;
  background-color: #FFD65C !important;
  color: #927520 !important;
  text-align: center;
  margin:auto;
  width: max-content;
  line-height: 1.75;
  padding:7px;
}
.pagebuttonitem:hover {
  text-decoration: none;
  color: #FFD65C !important;
  background-color: #927520 !important;
}
.appbar {
  background-color: #431F70 !important;
  color: #BC9BE6 !important;
  margin-bottom: 2em;;
}
.ccheadersection {
  background-color: #431F70 !important;
  color: #BC9BE6 !important;
  padding-left: 0px;
  padding-bottom: 2%;
  margin-left: 0px;
}
.termsandconditions {
  clear: both;
  float: right;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 200px;
  border-top: solid 1px #431F70;
}
.footer {
	position: fixed;
	bottom: 0;
	width: fit-content;
	height: 60px;
	line-height: 60px;
  border-top: solid 1px #431F70;
  border-right: solid 1px #431F70;
  border-left: solid 1px #431F70;
	background-color: #01DCE6;
  color: #000;
  display: inline-block;
  padding: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px;
}
.footericon {
  display: inline-block;
  padding-left: 13%;
  padding-right: 13%;
}
.footertext {
  display: inline-block;
  padding-bottom: 5%;
  text-align: center;
}
.MuiContainer-maxWidthLg {
    width: "100%";
    max-width: fit-content;
}
.multilineblockcontentwithimage {
  font-size: large;

}
.soloblockcontent {
  font-size: larger;
}

.pagecontainer p {
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
  padding-bottom: 1%;
}

.sublistavatar {
  height: 16px !important;
  width: 16px !important;
}

.color-primary-0 { color: #431F70 }	/* Main Primary color */
.color-primary-1 { color: #948C9E }
.color-primary-2 { color: #9359DB }
.color-primary-3 { color: #BC9BE6 }
.color-primary-4 { color: #2A0E4D }

.color-secondary-1-0 { color: #92908C }	/* Main Secondary color (1) */
.color-secondary-1-1 { color: #DFC882 }
.color-secondary-1-2 { color: #FFD65C }
.color-secondary-1-3 { color: #927520 }
.color-secondary-1-4 { color: #F1B500 }

.color-secondary-2-0 { color: #01DCE6 }	/* Main Secondary color (2) */
.color-secondary-2-1 { color: #58A6AA }
.color-secondary-2-2 { color: #4B6162 }
.color-secondary-2-3 { color: #018F96 }
.color-secondary-2-4 { color: #00696E }